<template>
    <div>
        <st-data-table
            :items="applications"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            :firstColumnSelectable="assigned"
            :allowSelectAll="assigned"
            :headClass="tableHeadClass"
            :serverSideSorting="true"
            :customBooleanTypeField="true"
            @view="onView"
            @edit="onEdit"
            @delete="onDelete"
            @itemChecked="checkItems"
            @viewStatus="viewSigningStatus"
            responsive="sm"
            hover
            stateModule="applications/list"
            ref="applicationList"
            actionsClass="st-application-action-class"
            :checkLimit="appConfig.BULK_SIGN_LIMIT"
            checkLimitMessage="APPLICATION.NOTIFIERS.BULK_SIGN_LIMIT"
        >
        </st-data-table>

        <application-signing-status-modal
            ref="signing-status-modal"
        >
        </application-signing-status-modal>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { ApplicationModel } from '@/modules/applications/models/application-model';
    import { ApplicationsPermissions } from '@/modules/applications/applications-permissions';
    import Roles from '@/security/roles';
    import ApplicationSigningStatusModal from '@/modules/applications/components/ApplicationSigningStatusModal.vue';

    const { fields } = ApplicationModel;

    export default {
        name: 'ApplicationsListTable',
        props: {
            assigned: {
                type: Boolean,
                default: false
            },
            headClass: {
                type: String,
                default: 'st-data-table-head'
            }
        },
        components: {
            ApplicationSigningStatusModal
        },
        data() {
            return {
                presenter: ApplicationModel.presenter,
                actions: [
                    {
                        name: 'view',
                        icon: 'eye',
                        tooltipText: this.$t('GENERAL.ACTIONS.VIEW'),
                        customIcon: false,
                        type: 'primary'
                    },
                    {
                        name: 'edit',
                        icon: 'edit',
                        tooltipText: this.$t('GENERAL.ACTIONS.EDIT'),
                        customIcon: false,
                        type: 'primary',
                        hideOnRow: true,
                    },
                    {
                        name: 'delete',
                        tooltipText: this.$t('GENERAL.ACTIONS.DELETE'),
                        icon: 'trash-alt',
                        customIcon: false,
                        type: 'danger',
                        hideOnRow: true,
                        customDeleteMsg: this.$t('APPLICATION.LIST.CONFIRM_DELETE_TEXT'),
                    },
                    {
                        name: 'viewStatus',
                        icon: 'signature',
                        tooltipText: this.$t('GENERAL.ACTIONS.VIEW_STATUS'),
                        customIcon: false,
                        type: 'primary',
                        hideOnRow: true,
                    }
                ],
                selected: [],
                selectedApplication: {},
            };
        },
        watch: {
            resetShowAllDoc(value) {
                this.$refs.applicationList.deselectAll(value);
            }
        },
        computed: {
            ...mapGetters({
                rows: 'applications/list/rows',
                currentUser: 'auth/currentUser',
                resetShowAllDoc: 'applications/form/resetShowAllDoc',
                isStaff: "auth/isStaff",
                appConfig: "shared/appConfig"
            }),
            hasPermissionToStaffApplications() {
                return new ApplicationsPermissions(this.currentUser).readStaff;
            },
            hasPermissionToStaffOwnApplications() {
                return new ApplicationsPermissions(this.currentUser).readOwnStaff;
            },
            hasPermissionToReadStaff() {
                return this.hasPermissionToStaffApplications || this.hasPermissionToStaffOwnApplications;
            },
            isSuperAdmin() {
                return this.currentUser.user_type_name === Roles.values['super_admin']
            },
            fields() {
                let tableFields = [];
                let selectedColumn = {label: '', key: 'selected'};

                if (this.assigned) {
                    tableFields.push(selectedColumn);
                }

                if (this.hasPermissionToStaffOwnApplications || this.isSuperAdmin) {
                    tableFields.push(
                        fields.identification_number.extendField({ customField: true, portalTarget: 'enable-truncate', sortable: true}),
                        fields.allocated_document_series.extendField({ sortable: true}),
                        fields.application_date.extendField({ bold: true, sortable: true}),
                        fields.last_review_date.extendField({ bold: true, sortable: true }),
                        fields.internal_status.extendField({ labelType: true , sortable: true}),
                        fields.response_due_in_days.extendField({ bold: true, sortable: true}),
                        fields.application_type_name.extendField({ customField: true, portalTarget: 'enable-truncate', sortable: true}),
                        fields.beneficiary.extendField({ customField: true, portalTarget: 'beneficiary-custom-field'}),
                        fields.locality.extendField({ customField: true, portalTarget: 'locality-custom-field'}),
                    );
                    if (this.hasPermissionToStaffApplications) {
                        if (this.assigned) {
                            tableFields = [
                                selectedColumn,
                                fields.assigned_staff_user_name.extendField({ customField: true, portalTarget: 'enable-truncate'})
                            ].concat(tableFields);
                        } else {
                            tableFields = [
                                fields.assigned_staff_user_name.extendField({ customField: true, portalTarget: 'enable-truncate'})
                            ].concat(tableFields);
                        }
                    }
                } else {
                    tableFields.push(
                        fields.identification_number.extendField({ sortable: true, customField: true, portalTarget: 'enable-truncate', tdClass: 'st-data-table-body-td'}),
                        fields.allocated_document_series.extendField({ sortable: true}),
                        fields.application_date.extendField({ bold: true, sortable: true}),
                        fields.application_type_name.extendField({ customField: true, portalTarget: 'enable-truncate', sortable: true}),
                        fields.locality.extendField({ customField: true, portalTarget: 'locality-custom-field'}),
                        fields.response_due_in_days.extendField({ bold: true, sortable: true}),
                        fields.created_date.extendField({ sortable: true}),
                        fields.beneficiary.extendField({ customField: true, portalTarget: 'beneficiary-custom-field'}),
                        fields.is_paid.extendField({ booleanType: true }),
                        fields.is_signed.extendField({ booleanType: true }),
                        fields.status.extendField({ labelType: true , sortable: true})
                    );
                }

                return tableFields;
            },
            applications() {
                return this.rows.map((row) => {
                    let hideAction = true
                    let hideActionName = ''
                    if (this.isStaff && this.currentUser.user_id && row.assigned_staff_user_id === this.currentUser.user_id) {
                        hideActionName = 'edit';
                    }
                    if (!this.isStaff && row.status === 'draft') {
                        hideActionName = 'delete';
                    }

                    if (this.isStaff) {
                        if (row.internal_status !== 'sign_pending' && row?.to_sign_by_assigned_staff ||
                            ['sign_pending', 'signed'].includes(row.internal_status)) {
                            hideActionName = 'viewStatus';
                        }
                    }

                    return {
                        ...row,
                        hideAction,
                        hideActionName
                    }
                });
            },
            tableHeadClass() {
                let headerClass = this.headClass;

                if (this.assigned) {
                    headerClass += ' first-column-check';
                }

                return headerClass;
            }
        },
        methods: {
            ...mapActions({
                selectRecord: 'applications/form/find',
                doSelect: 'applications/list/doSelect',
                doRemove: 'applications/list/doRemove',
            }),
            async onView(data) {
                if (!data?.item?.id) return;
                const application = await this.selectRecord({ id: data.item.id, loadStaff: this.hasPermissionToReadStaff});

                const routeName =  application?.status === 'draft' ? 'applicationEdit' : 'applicationView';
                this.$router.push({
                    name: routeName,
                    params: {
                        id: data.item.id,
                    },
                });
            },
            onEdit(application) {
                this.$router.push({
                    params: {
                        id: application.item.id,
                        activeTab: 'listDocuments'
                    },
                    name: 'applicationView'
                });
            },
            onDelete(application) {
                this.doRemove(application.item.id)
                .then(()=> {
                    this.$emit('refreshApplicationsList')
                })
            },
            checkItems(data) {
                if (this.appConfig?.BULK_SIGN_LIMIT) {

                }
                this.doSelect(data);
            },
            async viewSigningStatus(data) {
                if (!data?.item?.id) return;
                await this.selectRecord({ id: data.item.id, loadStaff: this.hasPermissionToReadStaff});
                this.$refs['signing-status-modal'].show();
            }
        },
    };
</script>
