<template>
    <st-modal
        ref="modal"
        hide-header-delimiter
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
        hideable
        size="md"
        :title="$t('APPLICATION.MODALS.SIGN_STATUS.TITLE')"
        :id="modalId"
        :hideHeaderDelimiter="false"
    >
        <template #body>
            <div class="fs-14">
                <div class="row pt-0 pb-2">
                    <div class="col-md-6 font-weight-bold">{{$t('APPLICATION.MODALS.SIGN_STATUS.IDENTIFICATION_NUMBER')}}:</div>
                    <div class="col-md-6">{{record.identification_number}}</div>
                </div>
                <div class="row pb-2">
                    <div class="col-md-6 font-weight-bold">{{$t('APPLICATION.MODALS.SIGN_STATUS.LOCALITY')}}:</div>
                    <div class="col-md-6">{{locality}}</div>
                </div>
                <div class="row pb-2" v-if="citizenLastSignDate">
                    <div class="col-md-6 font-weight-bold">{{$t('APPLICATION.MODALS.SIGN_STATUS.LAST_SIGNING_DATE_CITIZEN')}}:</div>
                    <div class="col-md-6">{{citizenLastSignDate}}</div>
                </div>
                <div class="row pb-2">
                    <div class="col-md-6 font-weight-bold">{{$t('APPLICATION.MODALS.SIGN_STATUS.LAST_SIGNING_DATE')}}:</div>
                    <div class="col-md-6">{{lastSignDate}}</div>
                </div>
                <div class="row">
                    <div class="col-md-6 font-weight-bold">{{$t('APPLICATION.MODALS.SIGN_STATUS.SIGNING_BY')}}</div>
                    <div class="col-md-6">{{toSignBy}}</div>
                </div>
            </div>
        </template>
    </st-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { generateRandomId } from '@/core/helpers/globalMethods';
import moment from 'moment';

export default {
    name: "ApplicationSigningStatusModal",
    data() {
        return {
            processingAction: [],
            resolvedAction: [],
            modalId: `st-app-modal-sign-status-${generateRandomId()}`,
            assignedUser: {}
        };
    },
    computed: {
        ...mapGetters({
            currentUser: "auth/currentUser",
            accountDetails: "auth/accountDetails",
            record: "applications/form/record",
        }),
        locality() {
            return this.record.form?.real_estate_target?.map((el) => el.territorial_administrative_unit).join(',')
        },
        lastSignDate() {
            if (this.resolvedAction.length && 'SIGN_DOCUMENT' === this.resolvedAction[0].action_type) {
                return moment(this.resolvedAction[0].updated_date).format('DD.MM.YYYY HH:mm');
            }

            return '-';
        },
        citizenLastSignDate() {
            if (this.resolvedAction.length && 'SIGN_APPLICANT_USER' === this.resolvedAction[0].action_type) {
                return moment(this.resolvedAction[0].updated_date).format('DD.MM.YYYY HH:mm');
            }

            return false;
        },
        toSignBy() {
            if (this.record.to_sign_by_assigned_staff) {
                return this.assignedUser.functions?.join(',')
            } else {
                if (this.processingAction.length) {
                    const isCitizenSignStep = this.processingAction[0].action_type === 'SIGN_APPLICANT_USER';
                    return isCitizenSignStep ? this.$t('APPLICATION.MODALS.SIGN_STATUS.CITIZEN_SIGNING') : this.processingAction[0].function_type;
                }
            }

            return '-';
        }
    },
    methods: {
        ...mapActions({
            doFindUserActions: "applications/form/findUserActions",
            resetUserActions: "applications/form/doResetUserActions",
            doFind: "applications/form/find",
            findUser: "applications/form/doFindAssignedStaffAccount"
        }),
        async show() {
            this.$refs["modal"].show();
        },
        async hide() {
            this.resetUserActions();
            await this.doFind({id: this.record.id, loadStaff: true});

            this.$refs["modal"].hide();
        },
        async getUserActions() {
            if (this.record) {
                const paramsProcessing = {
                    query: `application_id eq ${this.record.id} and status eq PROCESSING`,
                    sort: '-created_date'
                };

                const paramsResolved = {
                    query: `application_id eq ${this.record.id} and status eq RESOLVED`,
                    sort: '-updated_date',
                    limit: 1
                };

                this.processingAction = await this.doFindUserActions(paramsProcessing);
                this.resolvedAction = await this.doFindUserActions(paramsResolved);
            }
        }
    },
    async mounted() {
        this.$root.$on('bv::modal::shown', async (modalObj) => {
            if (modalObj.componentId === this.modalId) {
                await this.getUserActions();
                if (this.record?.assigned_staff_user_id !== this.currentUser.user_id) {
                    this.assignedUser = await this.findUser(this.record?.assigned_staff_user_id);
                }
            }
        });
    },
    created() {
        this.assignedUser = this.accountDetails;
    }
};
</script>

<style scoped>
    .fs-14 {
        font-size: 14px;
    }
</style>
